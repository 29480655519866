export const MAC_CONFIG_PROFILE_BODY = {
  "@odata.type": "#microsoft.graph.macOSCustomConfiguration",
  description: "Configuration settings managed by Atomus",
  displayName: "Atomus Configuration Profile",
  payloadName: "Atomus configuration profile payload",
  payloadFileUrl:
    "https://aegisfastblob.blob.core.windows.net/resources/com.atomus.aegis.plist",
  payloadFileName: "com.atomuscyber.aegis.mobileconfig",
} as const;

export const TokenScopes = {
  InternalApi: {
    COMMERCIAL: [
      "api://b2efaee4-0a98-4643-b207-f3d48fd0e7d8/user_impersonation",
    ],
    GOV: ["api://f20360f2-ba74-46b5-9f3c-03420432d1fb/user_impersonation"],
  },
  ExternalApi: {
    COMMERCIAL: ["api://atomus-aegis-api/user-impersonation"],
    GOV: ["api://atomus-aegis-api/user-impersonation"],
  },
  Arm: {
    COMMERCIAL: ["https://management.azure.com/user_impersonation"],
    GOV: ["https://management.usgovcloudapi.net/user_impersonation"],
  },
} as const;

export const ErrorMessages = {
  AEGIS_VERSION_LOG_NOT_FOUND: "could not find any Aegis inventory logs",
};

export const SupportedPlatforms = {
  ALL: "all",
  MAC: "darwin",
  WIN: "win32",
  LINUX: "linux",
} as const;

export const DEFAULT_USER = {
  userType: "user",
  firstName: "",
  lastName: "",
  username: "",
  email: "",
  msftTempPass: "",
  phone: "",
  numberOfDevices: 0,
  devices: [],
};

export const NOT_APPLICABLE_STR = "Not applicable";

export const IntuneMobileDeviceTypes = [
  "winmo6",
  "nokia",
  "windowsphone",
  "wince",
  "winembedded",
  "iphone",
  "ipad",
  "ipod",
  "android",
  "hololens",
  "androidforwork",
  "blackberry",
  "palm",
];

export const DeviceOSToSupportedPlatform: Record<
  string,
  Exclude<(typeof SupportedPlatforms)[keyof typeof SupportedPlatforms], "all">
> = {
  Linux: "linux",
  MacMDM: "darwin",
  Windows: "win32",
};

export enum DeviceOnboardingStatus {
  notOnboarded = "Not onboarded",
  onboarded = "onboarded",
}

export const UserTypes = {
  OFFLINE: "offlineUser",
} as const;
