import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { lazy, Suspense, useContext, useEffect, useRef, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import LoadingBar from "../../Components/LoadingBar";
import Page from "../../Components/PageContainer";
import AuthContext from "../../contexts/AuthContext";
import { useTracking } from "../../hooks/tracking.hooks";
import {
  useOptionalFeatureStatus,
  useTenants,
} from "../../hooks/tenants.hooks";
import useMicrosoftToken from "../../hooks/tokens.hooks";
import { getSubscriptionId } from "../../services/atomus-internal-apis/atomus-internal-apis.service";

import Header from "./Header";
import SubHeader from "./SubHeader";
import { AccountSetupContextProvider } from "./AccountSetupSection/AccountSetupContext";
import { TenantContext } from "./TenantContext";
import { DevicesSectionContextProvider } from "./DevicesSection/DevicesSectionContext";
import { useCloudflareConnectionStatus } from "../../hooks/cloudflare.hooks";

const DevicesSection = lazy(() => import("./DevicesSection"));
const UsersSection = lazy(() => import("./UsersSection"));
const SecuritySection = lazy(() => import("./SecuritySection"));
const UpdatesSection = lazy(() => import("./UpdatesSection"));
const InvoicesSection = lazy(() => import("./InvoicesSection"));
const AccountSetupSection = lazy(() => import("./AccountSetupSection"));
const ActivitySection = lazy(() => import("./ActivitySection/ActivitySection"));
const AdminConsentSection = lazy(() => import("./AdminConsentSection"));
const UsbsSection = lazy(() => import("./UsbsSection"));
const CloudflareSection = lazy(() => import("./CloudflareSection"));

// when defender permissions are lacking admin consent, this error code is returned
// by microsoft
const ADMIN_CONSENT_ERR_PREFIX = "invalid_grant: AADSTS65001";

export default function TenantOverviewPage() {
  const { tenantId, tabId } = useParams();
  const navigate = useNavigate();
  const { getInternalApiToken } = useMicrosoftToken();
  const usbFeatureQuery = useOptionalFeatureStatus("usbs", tenantId);
  const usbsAvailable = !!usbFeatureQuery.data?.availableForTenant;

  const { data: cloudflareAvailable } = useCloudflareConnectionStatus(
    tenantId || ""
  );

  const [needsConsent, setNeedsConsent] = useState<boolean | undefined>();
  const [gotSubscriptionId, setGotSubscriptionId] = useState<boolean | string>(
    true
  );
  const { data, isLoading } = useTenants();
  const { role } = useContext(AuthContext);
  const { setTenant } = useContext(TenantContext);
  const tenant = data?.mappedTenants.find(
    (tenant) => tenant.tenantId === tenantId
  );
  const domain = tenant?.domain;
  const companyName = tenant?.name;
  const shortForm = tenant?.shortform ?? "";

  const isMounted = useRef(false);
  const { trackTabClick } = useTracking();

  useEffect(() => {
    if (!isMounted.current && domain) {
      isMounted.current = true;
      const getSubId = async () => {
        const token = await getInternalApiToken();
        try {
          await getSubscriptionId(token, tenantId as string);
        } catch (error) {
          setGotSubscriptionId(String(error));
        }
      };
      getSubId()
        .then(() => {
          setNeedsConsent(false);
        })
        .catch((error) => {
          if ((error as Error).message.startsWith(ADMIN_CONSENT_ERR_PREFIX)) {
            setNeedsConsent(true);
          }
        });
    }
  }, [getInternalApiToken, tenantId, domain]);

  useEffect(() => {
    setTenant(tenant ?? null);
  }, [setTenant, tenant]);

  if (isLoading) {
    return (
      <Page>
        <LoadingBar />
      </Page>
    );
  }

  if (!domain) {
    return <Navigate to="/" />;
  }

  if (!companyName) {
    alert("Error: company name is not defined");
    return <Navigate to="/" />;
  }

  if (!tenantId) {
    alert("Could not find tenantId in params");
    navigate("/");
    return <></>;
  }

  const tabs = [
    <Tab key="devices-tab" onClick={() => trackTabClick("Devices")}>
      Devices
    </Tab>,
    <Tab key="users-tab" onClick={() => trackTabClick("Users")}>
      Users
    </Tab>,
  ];

  const tabPanels = [
    <TabPanel key="devices-panel">
      <DevicesSectionContextProvider>
        <DevicesSection tenantId={tenantId} />
      </DevicesSectionContextProvider>
    </TabPanel>,
    <TabPanel key="users-panel">
      <UsersSection tenantId={tenantId} />
    </TabPanel>,
  ];

  if (role === "securityAdmin") {
    tabs.push(
      <Tab key="security-tab">Security</Tab>,
      <Tab key="updates-tab">Updates</Tab>,
      <Tab key="invoices-tab">Invoices</Tab>,
      <Tab key="account-setup-tab">Account setup</Tab>,
      <Tab key="activity-tab">Activity</Tab>
    );
    tabPanels.push(
      <TabPanel key="security-panel">
        <SecuritySection tenantId={tenantId} />
      </TabPanel>,
      <TabPanel key="updates-panel">
        <UpdatesSection tenantId={tenantId} />
      </TabPanel>,
      <TabPanel key="invoices-panel">
        <InvoicesSection tenantId={tenantId} />
      </TabPanel>,
      <TabPanel key="account-setup-panel">
        <AccountSetupContextProvider tenantId={tenantId}>
          <AccountSetupSection tenantId={tenantId} />
        </AccountSetupContextProvider>
      </TabPanel>,
      <TabPanel key="activity-panel">
        <ActivitySection tenantId={tenantId} />
      </TabPanel>
    );
  }

  if (usbsAvailable) {
    tabs.push(
      <Tab key="usbs-tab" onClick={() => trackTabClick("Usbs")}>
        USBs
      </Tab>
    );
    tabPanels.push(
      <TabPanel key="usbs-panel">
        <UsbsSection tenantId={tenantId} />
      </TabPanel>
    );
  }

  if (cloudflareAvailable) {
    tabs.push(<Tab key="cloudflare-tab">Cloudflare</Tab>);
    tabPanels.push(
      <TabPanel key="cloudflare-panel">
        <CloudflareSection tenantId={tenantId} />
      </TabPanel>
    );
  }

  // The tab id used in the url is without the -tab suffix
  const visibleTabIds = tabs.map((tab) => {
    return tab?.key?.toString().replace(/-tab$/, "");
  });

  if (!visibleTabIds.includes(tabId)) {
    return <Navigate to={`/tenants/${tenantId}/devices`} />;
  }

  return (
    <Page>
      <Header
        companyName={companyName}
        domain={domain}
        tenantId={tenantId}
        shortForm={shortForm}
      />
      {gotSubscriptionId !== true && (
        <>
          <Text color="red">Couldn't get subscription id:</Text>
          <Text color="red">{gotSubscriptionId}</Text>
        </>
      )}
      {needsConsent === undefined && <LoadingBar />}
      {needsConsent === true && <AdminConsentSection tenantId={tenantId} />}
      {needsConsent === false && gotSubscriptionId === true && (
        <>
          <SubHeader tenantId={tenantId} />
          <Tabs
            variant="enclosed"
            colorScheme="blue"
            isLazy
            defaultIndex={visibleTabIds.indexOf(tabId)}
            onChange={(index) => {
              const tabId = visibleTabIds[index];
              navigate(`/tenants/${tenantId}/${tabId}`);
            }}
          >
            <TabList>{tabs.map((tab) => tab)}</TabList>
            <TabPanels>
              {tabPanels.map((panel) => (
                <Suspense
                  key={panel.key}
                  fallback={
                    <Box marginY={4}>
                      <LoadingBar />
                    </Box>
                  }
                >
                  {panel}
                </Suspense>
              ))}
            </TabPanels>
          </Tabs>
        </>
      )}
    </Page>
  );
}
