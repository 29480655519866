import {
  Button,
  ButtonGroup,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  UseDisclosureReturn,
  Text,
} from "@chakra-ui/react";
import { BsX, BsCheck } from "react-icons/bs";

export default function ResetConfirmationModal({
  disclosure,
  confirmMsg,
  onConfirm,
}: Readonly<{
  disclosure: UseDisclosureReturn;
  confirmMsg: string;
  onConfirm: <T extends object>(newValue: T, isReset?: boolean) => Promise<void>;
}>) {
  return (
    <Modal {...disclosure}>
      <ModalOverlay />
      <ModalContent top="5rem">
        <ModalBody display="flex" flexDir="column" gap="8px">
          <Heading size="md">Are you sure?</Heading>
          <Text>{confirmMsg}</Text>
        </ModalBody>
        <ModalFooter>
          <Flex flexDir="row-reverse">
            <ButtonGroup>
              <Button
                size="xs"
                onClick={disclosure.onClose}
                variant="ghost"
                rightIcon={<BsX />}
              >
                Cancel
              </Button>
              <Button
                size="xs"
                variant="ghost"
                colorScheme="red"
                rightIcon={<BsCheck />}
                onClick={() => {
                  onConfirm({}, true);
                  disclosure.onClose();
                }}
              >
                Confirm
              </Button>
            </ButtonGroup>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
