import { CopyIcon, InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  IconButton,
  Spacer,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  Collapse,
} from "@chakra-ui/react";
import { useContext } from "react";
import { BsGear } from "react-icons/bs";
import { useOptionalFeatureStatus } from "../../../hooks/tenants.hooks";
import { toastError, toastSuccess } from "../../../Providers/ToastProvider";
import { TenantContext } from "../TenantContext";
import PreferencesModal from "./PreferencesModal/PreferencesModal";
import TenantLinkMenu from "./TenantLinkMenu";

interface IHeaderProps {
  companyName: string;
  domain: string;
  tenantId: string;
  shortForm: string;
}

export default function Header({
  companyName,
  domain,
  tenantId,
  shortForm,
}: Readonly<IHeaderProps>) {
  const toast = useToast();
  const settingsDisclosure = useDisclosure();
  const detailsDisclosure = useDisclosure({ defaultIsOpen: false });
  const {
    data: backupSettingsAvailability,
    isFetching: backupAvailabilityFetching,
  } = useOptionalFeatureStatus("backup:settings", tenantId);
  const { data: vpnSettingsAvailability, isFetching: vpnAvailabilityFetching } =
    useOptionalFeatureStatus("vpn:settings", tenantId);

  const { tenant } = useContext(TenantContext);

  const handleCopyToClipboard = async (copyText: string) => {
    try {
      await navigator.clipboard.writeText(copyText);
      // Read clipboard text
      const clipText = await navigator.clipboard.readText();
      const editorElement: HTMLElement | null =
        document.querySelector(".editor");
      if (editorElement) {
        editorElement.innerText += clipText;
      }
      toastSuccess(toast, "Copied to clipboard");
    } catch (error) {
      toastError(toast, error);
    }
  };

  const showPreferences =
    !backupAvailabilityFetching &&
    !vpnAvailabilityFetching &&
    (backupSettingsAvailability?.availableForTenant ||
      vpnSettingsAvailability?.availableForTenant);

  return (
    <>
      {showPreferences && <PreferencesModal disclosure={settingsDisclosure} />}
      <Box marginY="12px">
        <Flex alignItems="baseline" gap="12px">
          <Flex alignItems="center" gap="2">
            <Text fontSize="2xl" fontWeight="semibold">
              {companyName}
            </Text>
            <Tooltip
              label={`Click to ${
                detailsDisclosure.isOpen ? "hide" : "view"
              } tenant details`}
              hasArrow
            >
              <IconButton
                aria-label="show-details"
                icon={<InfoIcon boxSize="0.9rem" color="fg.muted" />}
                size="sm"
                variant="ghost"
                minW="24px"
                height="24px"
                onClick={detailsDisclosure.onToggle}
                _hover={{ bg: "bg.muted" }}
              />
            </Tooltip>
          </Flex>
          <TenantLinkMenu tenantId={tenantId} />
          <Spacer />
          {showPreferences && (
            <IconButton
              aria-label="settings-button"
              icon={<BsGear />}
              size="sm"
              variant="ghost"
              onClick={settingsDisclosure.onOpen}
            />
          )}
        </Flex>
        <Collapse in={detailsDisclosure.isOpen} animateOpacity>
          <Box mt={2}>
            <Flex direction="column" gap={2}>
              <Flex alignItems="center" gap={2}>
                <Text fontSize="lg" color="fg">
                  {shortForm}
                </Text>
                <IconButton
                  aria-label="copy-tenant-id"
                  size="sm"
                  variant="ghost"
                  onClick={() => handleCopyToClipboard(shortForm)}
                  icon={<CopyIcon />}
                />
              </Flex>
              <Flex alignItems="center" gap={2}>
                <Tooltip
                  isDisabled={
                    domain?.toLowerCase() ===
                    tenant?.compliantDomain?.toLowerCase()
                  }
                  label={`Microsoft domain does not match the Atomus registered compliant domain: ${
                    tenant?.compliantDomain ?? "N/A"
                  }`}
                >
                  <Text
                    fontSize="lg"
                    color={
                      domain?.toLowerCase() !==
                      tenant?.compliantDomain?.toLowerCase()
                        ? "red"
                        : "fg.muted"
                    }
                  >
                    {domain}
                  </Text>
                </Tooltip>
                <IconButton
                  aria-label="copy-domain"
                  size="sm"
                  variant="ghost"
                  onClick={() => handleCopyToClipboard(domain)}
                  icon={<CopyIcon />}
                />
              </Flex>
              <Flex alignItems="center" gap={2}>
                <Text fontSize="lg" color="fg.muted">
                  {tenantId}
                </Text>
                <IconButton
                  aria-label="copy-tenant-id"
                  size="sm"
                  variant="ghost"
                  onClick={() => handleCopyToClipboard(tenantId)}
                  icon={<CopyIcon />}
                />
              </Flex>
            </Flex>
          </Box>
        </Collapse>
      </Box>
    </>
  );
}
