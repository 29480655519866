import { useQuery } from "react-query";
import {
  CloudflareDevice,
  CloudflareTunnel,
  TunnelManagement,
} from "../services/atomus-internal-apis/atomus-internal-apis.types";
import useMicrosoftToken from "./tokens.hooks";
import {
  getTunnelManagement,
  internalApiInstance,
} from "../services/atomus-internal-apis/atomus-internal-apis.service";

export function useCloudflareConnectionStatus(tenantId: string) {
  const { getInternalApiToken } = useMicrosoftToken();

  return useQuery({
    queryKey: ["cloudflare-connection-status", tenantId],
    queryFn: async () => {
      const token = await getInternalApiToken();
      const response = await internalApiInstance.get<{
        data: boolean;
      }>(`/tenants/${tenantId}/integrations/cloudflare/connection`, {
        headers: { authorization: token },
      });
      return response.data.data;
    },
  });
}

export function useCloudflareDevices(tenantId: string) {
  const { getInternalApiToken } = useMicrosoftToken();
  const { data: isConnected } = useCloudflareConnectionStatus(tenantId);

  return useQuery({
    queryKey: ["cloudflare-devices", tenantId],
    queryFn: async () => {
      if (!isConnected) return [];

      const token = await getInternalApiToken();
      const response = await internalApiInstance.get<{
        data: CloudflareDevice[];
      }>(`/tenants/${tenantId}/integrations/cloudflare/devices`, {
        headers: { authorization: token },
      });
      return response.data.data;
    },
    enabled: isConnected !== undefined,
  });
}

export function useCloudflareTunnels(tenantId: string) {
  const { getInternalApiToken } = useMicrosoftToken();
  const { data: isConnected } = useCloudflareConnectionStatus(tenantId);

  return useQuery({
    queryKey: ["cloudflare-tunnels", tenantId],
    queryFn: async () => {
      if (!isConnected) return [];

      const token = await getInternalApiToken();
      const response = await internalApiInstance.get<{
        data: CloudflareTunnel[];
      }>(`/tenants/${tenantId}/integrations/cloudflare/tunnels`, {
        headers: { authorization: token },
      });
      return response.data.data;
    },
    enabled: isConnected !== undefined,
  });
}

export function useTunnelManagement(
  tenantId: string,
  tunnelId: string,
  connectorId?: string,
  options?: { enabled?: boolean }
) {
  const { getInternalApiToken } = useMicrosoftToken();

  return useQuery<TunnelManagement | null>(
    ["tunnel-management", tenantId, tunnelId, connectorId],
    async () => {
      if (!connectorId) return null;
      const token = await getInternalApiToken();
      return getTunnelManagement(token, tenantId, tunnelId, connectorId);
    },
    {
      enabled: options?.enabled && !!connectorId,
      refetchOnMount: true,
    }
  );
}
