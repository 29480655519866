import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  semanticTokens: {
    colors: {
      bg: {
        default: "white",
        _dark: "black",
      },
      "bg.subtle": {
        default: "gray.50",
        _dark: "gray.950",
      },
      "bg.muted": {
        default: "gray.100",
        _dark: "gray.900",
      },
      "bg.emphasized": {
        default: "gray.200",
        _dark: "gray.800",
      },
      "bg.inverted": {
        default: "black",
        _dark: "white",
      },
      "bg.panel": {
        default: "white",
        _dark: "gray.950",
      },
      "bg.error": {
        default: "red.50",
        _dark: "red.950",
      },
      "bg.warning": {
        default: "orange.50",
        _dark: "orange.950",
      },
      "bg.success": {
        default: "green.50",
        _dark: "green.950",
      },
      "bg.info": {
        default: "blue.50",
        _dark: "blue.950",
      },
      fg: {
        default: "black",
        _dark: "gray.50",
      },
      "fg.muted": {
        default: "gray.600",
        _dark: "gray.400",
      },
      "fg.subtle": {
        default: "gray.400",
        _dark: "gray.500",
      },
      "fg.inverted": {
        default: "gray.50",
        _dark: "black",
      },
      "fg.error": {
        default: "red.500",
        _dark: "red.400",
      },
      "fg.warning": {
        default: "orange.600",
        _dark: "orange.300",
      },
      "fg.success": {
        default: "green.600",
        _dark: "green.300",
      },
      "fg.info": {
        default: "blue.600",
        _dark: "blue.300",
      },
      border: {
        default: "gray.200",
        _dark: "gray.800",
      },
      "border.muted": {
        default: "gray.100",
        _dark: "gray.900",
      },
      "border.subtle": {
        default: "gray.50",
        _dark: "gray.950",
      },
      "border.emphasized": {
        default: "gray.300",
        _dark: "gray.700",
      },
      "border.inverted": {
        default: "gray.800",
        _dark: "gray.200",
      },
      "border.error": {
        default: "red.500",
        _dark: "red.400",
      },
      "border.warning": {
        default: "orange.500",
        _dark: "orange.400",
      },
      "border.success": {
        default: "green.500",
        _dark: "green.400",
      },
      "border.info": {
        default: "blue.500",
        _dark: "blue.400",
      },
    },
  },
});

export default theme;
