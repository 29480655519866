import { useMsal } from "@azure/msal-react";
import { Button, Flex, HStack, Image, Spacer, Text } from "@chakra-ui/react";
import { useState, useEffect, useContext } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import Logo from "../../assets/icon.png";
import { ColorModeToggle } from "../ColorModeToggle";

export default function Navbar() {
  const [accountName, setAccountName] = useState<string>("");
  const { instance } = useMsal();
  const { isGov, setIsGov, role } = useContext(AuthContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleLogout = async () => {
    queryClient.removeQueries();
    queryClient.clear();
    await instance.logoutPopup({ account: instance.getActiveAccount() });
    setIsGov(!isGov);
  };

  const handleSwitch = () => {
    setIsGov(!isGov);
    navigate("/tenants");
  };

  useEffect(() => {
    const account = instance.getActiveAccount();
    if (account) {
      setAccountName(account.username);
    }
  }, [instance]);

  return (
    <Flex alignItems="center">
      <HStack marginX={8} marginY={4} alignItems="center" spacing={6}>
        <HStack spacing="4px" alignItems="center">
          <Image src={Logo} width="80px" height="auto" />
          <Spacer w={4} />
          <Text fontSize="xl" fontWeight="semibold">
            Atomus Security Dashboard
          </Text>
        </HStack>

        {role === "securityAdmin" && (
          <Button
            onClick={() => navigate("/tenants")}
            variant="ghost"
            colorScheme="blue"
          >
            Tenants
          </Button>
        )}

        <ColorModeToggle />
      </HStack>
      <Spacer />
      <HStack marginX={8} marginY={4} alignItems="center" spacing={4}>
        <Text>{accountName}</Text>
        {role === "securityAdmin" && (
          <Button onClick={handleSwitch} size="sm">
            Switch to {isGov ? "commercial" : "gov"}
          </Button>
        )}
        <Button onClick={handleLogout} size="sm">
          Logout
        </Button>
      </HStack>
    </Flex>
  );
}
